import "./ServiceDataFrame.css";

import React, { useState } from "react";

import FrameImage from "./cloud.png";
import apache from './kafka-.png'
import flink from './flink.jpg'
import full from './unnamed.png';
import {
    useEffect
} from "react";
import { useLocation } from "react-router-dom";

const ServiceDataFrame = () => {
  // State to track the currently visible section
  const [activeSection, setActiveSection] = useState("cloud");

  // Function to handle section change
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const location=useLocation();
  useEffect(() => {
      if (location.hash) {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);

  return (
    <section id="Service_Nav">
    <div className="container" >
      {/* Navbar */}
      <nav className="feature-cards__navbar">
        <ul className="feature-cards__nav-list">
          <li
            className={`feature-cards__nav-item ${
              activeSection === "cloud" ? "feature-cards__nav-item--active" : ""
            }`}
            onClick={() => handleSectionChange("cloud")}
          >
           Confluent Cloud
          </li>
          <li
            className={`feature-cards__nav-item ${
              activeSection === "apache"
                ? "feature-cards__nav-item--active"
                : ""
            }`}
            onClick={() => handleSectionChange("apache")}
          >
            Apache Kafka
          </li>
          <li
            className={`feature-cards__nav-item ${
              activeSection === "flink" ? "feature-cards__nav-item--active" : ""
            }`}
            onClick={() => handleSectionChange("flink")}
          >
            Apache Flink
          </li>
          <li
            className={`feature-cards__nav-item ${
              activeSection === "fullstack"
                ? "feature-cards__nav-item--active"
                : ""
            }`}
            onClick={() => handleSectionChange("fullstack")}
          >
            Full Stack
          </li>
        </ul>
      </nav>

      {/* Sections */}
      {activeSection === "cloud" && (
        <section className="data-stream-frame" id="cloud">
          <div className="image-section left">
            <img
              src={FrameImage}
              alt="Data Stream Frame"
              className="frame-image"
            />
          </div>
          <div className="content-section">
            <h2>
              Confluent Cloud: Streamlining Your Real-Time Data Infrastructure
            </h2>
            <p>
              Xstream Minds utilizes Confluent Cloud to deliver secure,
              real-time data streaming across its ecosystem. Xstream Minds
              collects and processes data from diverse sources, including
              structured data from data stores, real-time application data, and
              unstructured log or messaging data. These data streams are
              published to Kafka topics within Confluent Cloud, with schema
              enforcement ensuring compatibility and consistency across all
              integrations. Secure in-transit encryption safeguards the streams,
              enabling compliance with stringent data security standards.
              Xstream Minds empowers its partners and clients to access and
              process this data using Kafka clients built in widely-used
              programming languages such as Java, Python, C#, Go, and .NET. This
              architecture facilitates real-time insights, operational
              efficiency, and scalable data integration for Xstream Minds
              business solutions.
            </p>
          </div>
        </section>
      )}

      {activeSection === "apache" && (
        <section className="data-stream-frame" id="apache">
          <div className="content-section">
            <h2>Apache Kafka: The Backbone of Real-Time Data Streaming</h2>
            <p >
              At Xstream Minds, we leverage Apache Kafka for real-time data
              streaming, connecting systems and applications seamlessly. Kafka
              acts as a central hub, enabling data flow across web platforms,
              mobile apps, IoT devices, and CRMs. We integrate Kafka with
              databases to synchronize updates and power microservices with
              event-driven communication. Kafka supports real-time log
              aggregation, monitoring, and provides live data for analytics and
              machine learning. By streaming data into caches and data lakes, we
              ensure fast access and scalable storage for advanced analytics.
              With high throughput, resilience, and flexibility, Kafka drives
              instant decision-making and operational efficiency for our
              clients.
            </p>
          </div>
          <div className="image-section right">
            <img
              src={apache}
              alt="Data Stream Frame"
              className="frame-image"
            />
          </div>
        </section>
      )}

      {activeSection === "flink" && (
        <section className="data-stream-frame" id="flink">
          <div className="image-section left">
            <img
              src={flink}
              alt="Data Stream Frame"
              className="frame-image"
            />
          </div>
          <div className="content-section">
            <h2>
              Apache Flink: Advanced Stream Processing for Instant Insights
            </h2>
            <p>
              Xstream Mindsl leverages Apache Flink for real-time data
              processing and integration. Xstream Minds ingests data from
              various sources, such as applications, IoT devices, and historical
              datasets, into Flink's processing engine. Flink enables continuous
              data streaming and advanced queries, allowing Xstream Minds to
              derive actionable insights in real time. The processed data is
              stored in databases for reporting, used by applications for
              immediate decision-making, or delivered as new data streams for
              downstream systems. This robust setup empowers Xstream Minds to
              offer scalable, real-time analytics and event-driven solutions to
              its clients.
            </p>
          </div>
        </section>
      )}

      {activeSection === "fullstack" && (
        <section className="data-stream-frame" id="fullstack">
          <div className="content-section">
            <h2>Full Stack Development: Bridging Data and User Experience</h2>
            <p>
              At Xstream Minds, Full Stack Development is the foundation of our
              end-to-end digital solutions. We combine front-end and back-end
              development with real-time data processing technologies like
              Confluent Cloud, Apache Kafka, and Apache Flink to create
              integrated platforms that process, analyze, and present data in a
              seamless, actionable way. Our team leverages technologies like
              React, Node.js, Angular, and Python to build custom web
              applications, mobile interfaces, and enterprise solutions that
              connect directly to your real-time data streams, ensuring powerful
              systems that are intuitive and optimized for performance. By
              integrating AI-powered capabilities with Confluent Cloud, we
              enhance your applications with real-time, intelligent insights.
              From predictive analytics and customer sentiment analysis to
              personalized recommendations, we enable AI-driven solutions that
              automate decision-making and improve user experience. Whether it’s
              for smart dashboards or AI-based fraud detection, our Full Stack
              expertise ensures that your business systems are equipped to
              respond dynamically to the data that drives your success.
            </p>
          </div>
          <div className="image-section right">
            <img
              src={full}
              alt="Data Stream Frame"
              className="frame-image"
            />
          </div>
        </section>
      )}
    </div>
    </section>
  );
};

export default ServiceDataFrame;
