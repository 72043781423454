import Demo from './Demo';
import { Footer } from '../Footer/Footer';
import React from 'react'
import Slider from './Slider'

function Contact() {
  return (
    <div>
      <Slider></Slider>
      <Demo></Demo>
      <Footer></Footer>
    </div>
  )
}

export default Contact;
