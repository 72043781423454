import "./ServiceDigital.css";

import { Link } from "react-router-dom";
import React from "react";

export const ServiceDigital = () => {
  return (
    <div className="contain">
      <section id="cloud">
        <p className="servicedigital">
          At Xstream Minds, we provide cutting-edge digital solutions using
          
              Confluent Cloud
             
              Apache Kafka,
            
              Apache Flink,
            
            and
            
              Full Stack Development
           
          to drive innovation and growth. Through
          <span className="sp">
            {" "}
            <Link to="/Real" style={{ textDecoration: "none" }}>
              real-world applications
            </Link>
            {""} and{" "}
            <Link to="/domain" style={{ textDecoration: "none" }}>
              domain-specific{" "}
            </Link>{" "}
          </span>{" "}
          implementations, we enable real-time data streaming and sophisticated
          stream processing, helping businesses make informed decisions
          instantly. Our expertise in Fullstack Development ensures scalable
          applications with intuitive user interfaces, transforming data into
          actionable insights and optimizing operations to fuel growth.
        </p>
      </section>
    </div>
  );
};
