import './Solutions.css';

import React from 'react';
import img from './chart.png'; // Replace with the actual image path

const Solution = () => {
  return (
    <section className="solutions-wrapper">
      <div className="solutions-container">
        <div className="solutions-image">
          <img src={img} alt="Strategy Process" />
        </div>
        <div className="solutions-content">
          <h2 className="solutions-title">
            Redefining Success in Technology Consulting Through a Personalized Strategy
          </h2>
          <p className="solutions-description">
            Xstream Minds is revolutionizing the technology consulting services industry with our
            "Precision Customer Engagement Model," which tailors our proven services to meet the
            unique needs of each client. This approach ensures a deep understanding of our clients'
            objectives, fostering a collaborative strategy to achieve these goals while proactively
            identifying and eliminating inefficiencies that often hinder technology projects. With a
            comprehensive strategy, the right technology, and a focus on eliminating waste, projects
            are delivered more efficiently, consulting costs are reduced, and value is realized
            faster.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Solution;
