import Beliefs from './Belief/Belief';
import { BussinessSection } from './Bussiness/BussinessSection'
import {Footer} from '../Footer/Footer';
import { Potential } from './Potential/Potential'
import React from 'react';
import XstreamJourneyCard from './XstreamJourneyCard';
import { XstreamMinds } from './Inovation/Innovation';

function About() {
  return (
    <div>
      <Potential></Potential>
      <BussinessSection></BussinessSection>
      <Beliefs></Beliefs>
      <XstreamMinds></XstreamMinds>
    <XstreamJourneyCard></XstreamJourneyCard>
      <Footer></Footer>
    </div>
  )
}

export default About
