import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import About from "./Components/Aboutus/About";
import  Contact  from "./Components/Contact/Contact";
import DomainSpecific from "./Components/PrivacyPolicy/Privacy/DomainSpecific";
import Home from "./Components/Home/Home";
import PrivacyPolicy from "./Components/PrivacyPolicy/Privacy/Privacy";
import RealWorldApp from "./Components/PrivacyPolicy/Privacy/RealWorldApp";
import Sample from "./Components/Navbar/Sample";
import Scroll from "./Components/Scroll";
import Services from "./Components/Services/Services";
import { useEffect } from "react";

const ScrollToSection = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname) {
            const id = location.pathname.replace('/', ''); // Extract section id from pathname
            const section = document.getElementById(id);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [location]);

    return null; // No UI rendered by this component
};

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Sample />
                <ScrollToSection />
                <Scroll></Scroll>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/WhoWeAre" element={<Home />} />
                    <Route path="/AboutUs" element={<About />} />
                    <Route path="/Career" element={<Home />} />
                    <Route path="/ser" element={<Services></Services>}></Route>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/services" element={<Services></Services>}/>
                    <Route path="/Real" element={<RealWorldApp/>}/>
                    <Route path="/domain" element={<DomainSpecific/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
