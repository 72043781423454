import "./Sample.css";
import "/node_modules/bootstrap-icons/font/bootstrap-icons.css";

import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import logo from "./Group.png";

const Sample = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [contentMarginTop, setContentMarginTop] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);
  const location = useLocation(); // Get the current location

  const toggleMobileMenu = () => {
    if (isMobileView) {
      setIsMobileMenuOpen(!isMobileMenuOpen);
      setContentMarginTop(!isMobileMenuOpen ? 450 : 0); // Adjust the margin-top
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setIsMobileView(isMobile);

      if (!isMobile) {
        setIsMobileMenuOpen(false);
        setContentMarginTop(0);
      }
    };

    // Check initial screen size
    handleResize();

    // Add resize listener
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isActive = (path) => location.pathname === path; // Function to check active path

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
         <Link to="/"> <img src={logo} alt="Logo" className="logo" /></Link>
        </div>
        <div className="li-items">
          <ul className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
            <li className={`li-item ${isActive("/WhoWeAre") ? "active" : ""}`}>
              <Link to="/WhoWeAre" className="rout" style={{ textDecoration: "none", color: "#2f363f" }}>
                Who We Are
              </Link>
              <ul className="dropdown-menu">
                <Link to="/AboutUs" style={{ textDecoration: "none" }}>
                  <li className={`li-item ${isActive("/AboutUs") ? "active" : ""}`}>About Us</li>
                </Link>
                <Link to="/Career" style={{ textDecoration: "none" }}>
                  <li className={`li-item ${isActive("/Career") ? "active" : ""}`}>Careers</li>
                </Link>
              </ul>
            </li>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <li className={`li-item ${isActive("/services") ? "active" : ""}`}>Services</li>
            </Link>
            <li className={`li-item ${isActive("/Insights") ? "active" : ""}`}>
              Insights
              <ul className="dropdown-menu">
                <li className="li-item">News</li>
                <li className="li-item">Blog</li>
              </ul>
            </li>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <li className={`li-item ${isActive("/contact") ? "active" : ""}`}>Contact Us</li>
            </Link>
          </ul>
        </div>
        <div className="navbar-right">
          <a href="/contact#get"><button className="book-demo">Get In Touch</button></a>
          <div
            className={`mobile-menu-icon ${isMobileView ? "visible" : "hidden"}`}
            onClick={toggleMobileMenu}
            aria-label="Toggle mobile menu"
          >
            <span className="mobile-icon-menubar">☰</span>
          </div>
        </div>
      </nav>

      {/* Adjust the content's margin-top based on mobile menu */}
      <div style={{ marginTop: isMobileView ? contentMarginTop + "px" : "0px" }}>
        {/* Your page content */}
      </div>
    </>
  );
};

export default Sample;
