import "./Contactslider.css";

import React from 'react';

const Slider = () => {
  return (
    <>
    <div className="Contactslider-container">
      <div className="Contactslider-overlay"></div>
      <div className="Contactslider-content-wrapper">
        <div className="Contactslider-content">
          <h1 className="Contactslider-title">Join Our Team</h1>
        </div>
      </div>
    </div>
    
    </>
  );
};

export default Slider;