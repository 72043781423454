import "./Demo.css";

import * as Yup from "yup";

import React from "react";
import { useEffect } from 'react';
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";

const Demo = () => {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      organization: "",
      service: "",
      phoneNumber: "",
      email: "",
      message: "",
      agreement: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      organization: Yup.string().required("Organization is required"),
      service: Yup.string().required("Service is required"),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, "Phone Number must be 10 digits")
        .required("Phone Number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      message: Yup.string(),
      agreement: Yup.boolean()
        .oneOf([true], "You must agree to the terms")
        .required("Agreement is required"),
    }),
    onSubmit: async (values) => {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "212dfa66-83f0-4ef8-85a9-10f40c58d45d", // Replace with your Web3Forms access key
          ...values,
        }),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
      } else {
        alert("Error submitting the form. Please try again later.");
      }
    },
  });

  const location=useLocation();
    useEffect(() => {
        if (location.hash) {
          const element = document.querySelector(location.hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [location]);

  return (
    <>
    <section id="get">
    <div className="contact-form-container com">
      <h2 style={{textAlign:'center'}}>Contact Us</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className="error">{formik.errors.firstName}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className="error">{formik.errors.lastName}</div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <input
              type="text"
              name="organization"
              placeholder="Organization"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organization}
            />
            {formik.touched.organization && formik.errors.organization && (
              <div className="error">{formik.errors.organization}</div>
            )}
          </div>
          <div className="form-group">
            <select
              name="service"
              
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.service}
            >
              <option value="" className="serv">Select Service</option>
              <option value="Web Development">Confluent Cloud</option>
              <option value="3D Visualization">Apache Kafka</option>
              <option value="AR Solutions">Apache Flink</option>
              <option value="E-commerce">Others</option>
            </select>
            {formik.touched.service && formik.errors.service && (
              <div className="error">{formik.errors.service}</div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className="error">{formik.errors.phoneNumber}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="error">{formik.errors.email}</div>
            )}
          </div>
        </div>
        <div className="form-row">
          <textarea
            name="message"
            placeholder="Message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          ></textarea>
          {formik.touched.message && formik.errors.message && (
            <div className="error">{formik.errors.message}</div>
          )}
        </div>
        <div className="form-row">
          <label className="checkbox-container">
            <input
              type="checkbox"
              name="agreement"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.agreement}
            />
            By submitting this form, I agree to Xstream Minds Pvt Ltd.
          </label>
          {formik.touched.agreement && formik.errors.agreement && (
            <div className="error">{formik.errors.agreement}</div>
          )}
        </div>
        <button type="submit" className="submit-btn">
          Submit
        </button>
      </form>
    </div>
    </section>
    <section className="get"></section>
    </>
  );
};

export default Demo;
