import { Achievements } from './Achivements/Achivements';
import { Cards } from './Cards/Cards';
import Career from './Career/Career';
import  Carousel  from './Carousel/Carousel';
import { Footer } from '../Footer/Footer';
import { LearnExperts } from './Learn/Learn';
import React from 'react'
import Solution from './Solutions/Solutions';
import { Strategy } from './Strategy/Strategy';
import { TechnologyPartner } from './Tech/Technology';

function Home() {
  return (
    <div>
      <Carousel></Carousel>
      <Achievements></Achievements>
      <TechnologyPartner></TechnologyPartner>
      <Solution></Solution>
      <Strategy></Strategy>
      <Cards></Cards>
      <Career/>
      <LearnExperts></LearnExperts>
      <Footer></Footer>
      
    </div>
  )
}

export default Home
