import './Carousel.css';

import { useCallback, useEffect, useState } from 'react';

import CarouselControls from './CarouselControls';
import CarouselItem from './CarouselItem';
import { carouselData } from './CarouselData';

function Carousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = carouselData.length;

  const nextSlide = useCallback(() => {
    setCurrentSlide(current => (current === totalSlides - 1 ? 0 : current + 1));
  }, [totalSlides]);  // Memoize nextSlide with totalSlides as the dependency

  const prevSlide = useCallback(() => {
    setCurrentSlide(current => (current === 0 ? totalSlides - 1 : current - 1));
  }, [totalSlides]);  // Memoize prevSlide with totalSlides as the dependency

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(timer);
  }, [nextSlide]);  // Add nextSlide to the dependency array

  return (
    <div className="carousel">
      <div className="carousel-inner">
        {carouselData.map((slide, index) => (
          <CarouselItem 
            key={slide.id}
            slide={slide}
            isActive={index === currentSlide}
          />
        ))}
      </div>
      <CarouselControls 
        onPrevClick={prevSlide}
        onNextClick={nextSlide}
      />
    </div>
  );
}

export default Carousel;
