import './RealWorldApp.css';

import { Footer } from '../../Footer/Footer';
import React from 'react';

const RealWorldApp = () => {
  return (
    <>
    <div className="realworldapp-container">
      <header className="realworldapp-header">
        <h1>Real-World Applications</h1>
      </header>
      <main className="realworldapp-content">
        {/* Real-Time Analytics and Monitoring */}
        <section className="application">
          <h2>1. Real-Time Analytics and Monitoring</h2>
          <p><strong>Application:</strong> Businesses in industries like e-commerce, finance, and social media require real-time analytics to monitor user behavior, track financial transactions, and manage system health.</p>
          <p><strong>How It All Comes Together:</strong></p>
        
          <ul>
            <li>Confluent Cloud streams large amounts of event data (user actions, system logs, transactions) in real time.</li>
            <li>Apache Kafka ensures the data is transmitted reliably between systems and services.</li>
            <li>Apache Flink processes this data in real-time, performing analytics and identifying patterns or anomalies as they happen (e.g., fraudulent transactions, or unusual website traffic spikes).</li>
            <li>Full Stack Development builds dashboards and interfaces where business users can view these real-time insights, set up alerts, or take immediate actions based on the data.</li>
          </ul>
        </section>

        {/* Personalized Customer Experiences */}
        <section className="application">
          <h2>2. Personalized Customer Experiences</h2>
          <p><strong>Application:</strong> Retailers and online platforms can provide personalized recommendations based on real-time customer interactions with the site or mobile app.</p>
          <p><strong>How It All Comes Together:</strong></p>
          <ul>
            <li>Confluent Cloud streams real-time customer behavior data, such as browsing activity, search queries, and purchase history.</li>
            <li>Apache Kafka reliably distributes these events to systems for processing.</li>
            <li>Apache Flink processes the data streams, performing tasks like predicting customer preferences and recommending products in real-time.</li>
            <li>Full Stack Development integrates these recommendations into customer-facing applications (websites, mobile apps), delivering personalized content to the user instantly based on their actions.</li>
          </ul>
        </section>

        {/* Fraud Detection and Prevention */}
        <section className="application">
          <h2>3. Fraud Detection and Prevention</h2>
          <p><strong>Application:</strong> Financial institutions, online payment systems, and e-commerce platforms can use real-time data processing to detect and prevent fraud as it happens.</p>
          <p><strong>How It All Comes Together:</strong></p>
          <ul>
            <li>Confluent Cloud streams transaction data from multiple sources in real-time.</li>
            <li>Apache Kafka ensures this transaction data is reliably and securely distributed across various systems, including fraud detection engines.</li>
            <li>Apache Flink analyzes the data in real time to detect suspicious patterns (e.g., large, unexpected transactions, or multiple failed login attempts) and triggers alerts or preventive measures.</li>
            <li>Full Stack Development provides interfaces to allow analysts to monitor potential threats, approve or block transactions, and track fraud detection system performance.</li>
          </ul>
        </section>

        {/* IoT Data Stream Processing */}
        <section className="application">
          <h2>4. IoT Data Stream Processing</h2>
          <p><strong>Application:</strong> Industries such as manufacturing, transportation, and agriculture use IoT devices to collect real-time data from sensors (e.g., temperature, humidity, GPS location).</p>
          <p><strong>How It All Comes Together:</strong></p>
          <ul>
            <li>Confluent Cloud streams the data generated by IoT devices and sensors to a central data pipeline.</li>
            <li>Apache Kafka reliably handles large streams of sensor data, ensuring that it can be processed and consumed in real time.</li>
            <li>Apache Flink performs real-time analysis on the data, identifying trends and anomalies (e.g., equipment malfunctions, environmental changes).</li>
            <li>Full Stack Development creates dashboards and mobile apps that provide users with real-time insights into the status of devices, enabling immediate actions, such as sending maintenance alerts or adjusting operational parameters.</li>
          </ul>
        </section>

        {/* Real-Time Event-Driven Architectures for Microservices */}
        <section className="application">
          <h2>5. Real-Time Event-Driven Architectures for Microservices</h2>
          <p><strong>Application:</strong> In modern cloud-native applications, companies rely on microservices that interact with one another through events. For example, in a ride-sharing app, events might include booking a ride, driver assignment, payment processing, and feedback submission.</p>
          <p><strong>How It All Comes Together:</strong></p>
          <ul>
            <li>Confluent Cloud streams events between microservices, ensuring smooth communication and reliable data transfer between disparate components of the application.</li>
            <li>Apache Kafka acts as a messaging backbone, enabling asynchronous communication, decoupling services, and allowing them to operate independently while still coordinating in real-time.</li>
            <li>Full Stack Development ensures that the front-end interfaces and back-end services are tightly integrated, allowing customers and drivers to interact with real-time updates on their apps (e.g., ride status, payment completion).</li>
          </ul>
        </section>

        {/* Supply Chain Optimization */}
        <section className="application">
          <h2>6. Supply Chain Optimization</h2>
          <p><strong>Application:</strong> Large-scale supply chains benefit from real-time data integration to improve inventory management, predict demand, and optimize logistics.</p>
          <p><strong>How It All Comes Together:</strong></p>
          <ul>
            <li>Confluent Cloud streams data from multiple sources (e.g., sales data, inventory sensors, logistics tracking).</li>
            <li>Apache Kafka facilitates real-time communication between various systems in the supply chain, from inventory management to order fulfillment systems.</li>
            <li>Apache Flink processes this data in real-time, providing insights into inventory levels, demand forecasts, and delivery times, helping businesses proactively adjust supply chain strategies.</li>
            <li>Full Stack Development delivers custom dashboards for supply chain managers and stakeholders, allowing them to make data-driven decisions, view reports, and track key metrics, all in real-time.</li>
          </ul>
        </section>

        {/* Smart City Infrastructure */}
        <section className="application">
          <h2>7. Smart City Infrastructure</h2>
          <p><strong>Application:</strong> Smart cities use real-time data from sensors, cameras, traffic lights, and other infrastructure elements to optimize public services, traffic flow, and utilities.</p>
          <p><strong>How It All Comes Together:</strong></p>
          <ul>
            <li>Confluent Cloud streams data from various sources (e.g., traffic sensors, environmental data, utility meters).</li>
            <li>Apache Kafka enables real-time data distribution and ensures that information is efficiently shared across smart city systems.</li>
            <li>Apache Flink processes data streams for real-time analysis, such as detecting traffic congestion, monitoring air quality, or managing energy consumption.</li>
            <li>Full Stack Development integrates this data into web-based or mobile applications that city administrators can use to monitor and control urban infrastructure.</li>
          </ul>
        </section>

        {/* Data Integration across Legacy Systems */}
        <section className="application">
          <h2>8. Data Integration across Legacy Systems</h2>
          <p><strong>Application:</strong> Many enterprises have legacy systems that must be integrated with modern cloud-based applications. Confluent Cloud and Kafka enable real-time data migration and integration between old and new systems.</p>
          <p><strong>How It All Comes Together:</strong></p>
          <ul>
            <li>Confluent Cloud and Apache Kafka act as a bridge, facilitating the smooth transfer of data between legacy on-premise systems and cloud-based applications in real-time.</li>
            <li>Full Stack Development creates user-friendly interfaces for administrators to manage and view data integrations, offering dashboards for monitoring system health and data flow between systems.</li>
          </ul>
        </section>

        {/* Marketing Campaign Optimization */}
        <section className="application">
          <h2>9. Marketing Campaign Optimization</h2>
          <p><strong>Application:</strong> Companies running large-scale marketing campaigns require real-time monitoring and adjustment to improve performance.</p>
          <p><strong>How It All Comes Together:</strong></p>
          <ul>
            <li>Confluent Cloud streams real-time data from multiple channels (e.g., website interactions, social media engagement, and email responses).</li>
            <li>Apache Kafka aggregates this data from multiple marketing platforms to provide a comprehensive view of campaign performance.</li>
            <li>Apache Flink performs real-time analysis on this data, providing insights into customer engagement, conversion rates, and campaign effectiveness.</li>
            <li>Full Stack Development ensures that marketing teams can view real-time dashboards to adjust campaigns, segment audiences, and track ROI on the fly.</li>
          </ul>
        </section>
      </main>
      
    </div>
    <Footer></Footer>
    </>
  );
};

export default RealWorldApp;
