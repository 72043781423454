import React from "react";
import "./XstreamJourneyCard.css";

const XstreamJourneyCard = () => {
    return (
        <div className="journey-card">
            <h2 className="journey-title">The Xstream Minds Journey</h2>
            <p className="journey-content">
                Welcome to Xstream Minds, where innovation meets impact. Established in 2020, we are a
                dynamic software company dedicated to redefining how businesses leverage technology.
                Specializing in full-stack development, Confluent Cloud platform, and real-time data streaming
                technologies like Apache Kafka and Apache Flink, we transform complex challenges into
                scalable, future-ready solutions.
            </p>
            <p className="journey-content">
                From startups to established enterprises, we collaborate closely with our clients to deliver powerful,
                customized digital tools that drive real results. Our approach is rooted in creativity, adaptability,
                and a passion for solving challenges, whether it’s crafting seamless user experiences, optimizing
                cloud infrastructure, or building systems for real-time data insights.
            </p>
            <p className="journey-content">
                Over the years, we’ve partnered with visionary clients who trust us to bring their digital ambitions
                to life, and we remain committed to empowering businesses with tools and technologies that
                unlock their full potential. At Xstream Minds, innovation is just the beginning. Let’s build
                something extraordinary together.
            </p>
        </div>
    );
};

export default XstreamJourneyCard;
