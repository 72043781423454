import './Career.css';

import React, { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

const Career = () => {
  const location = useLocation();
  const careerSectionRef = useRef(null); // Reference to the Career section

  useEffect(() => {
    // Check if the user navigated to the /Career route
    if (location.pathname === "/Career" && careerSectionRef.current) {
      careerSectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  return (
    <div ref={careerSectionRef} id="Career" className="careers-section">
      <div className="careers-con">
        <div className="rows">
          <div className="text-centers">
            <h2 className="exptext">Innovate, Inspire, and Excel at Xstream Minds</h2>
            <p className="exppara">
              Join a team that thrives on pushing boundaries, solving complex challenges, and delivering world-class digital solutions. At Xstream Minds, we support your growth, inspire creativity, and provide the platform to succeed in a fast-paced, ever-evolving industry.
            </p>
           <a href="/contact#get"> <button className="btns">Join our Team</button></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
