import './Potential.css';

import React from 'react';

export const Potential = () => {
  return (
    <div className="heros-section">
      <div className="heros-overlay"></div>
      <div className="containe">
        <div className="heros-content text-center">
          <h1 className="heros-title">
            Harness the Potential of Data with Confluent
          </h1>
          <p className="heros-description">
            It reflects our commitment to providing innovative solutions and strategies that
            empower businesses to adapt, grow, and thrive in today's dynamic digital
            landscape.
          </p>
        </div>
      </div>
    </div>
  );
};
