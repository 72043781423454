import './Carousel.css';

import React from 'react';

function CarouselItem({ slide, isActive }) {
  return (
    <div className={`carousel-item ${isActive ? 'active' : ''}`}>
      <img 
        src={slide.image} 
        alt={slide.altText}  
        className="carousel-image" 
      />
      <div className="carousel-content">
        <h2>{slide.title}</h2>
        <p>{slide.description}</p>
      </div>
    </div>
  );
}

export default CarouselItem;
