import BackgroundVideo from "./ServiceVideo";
import { Footer } from "../Footer/Footer";
import React from "react";
import ServiceDataFrame from "./ServiceDataFrame";
import { ServiceDigital } from "./ServiceDigital";

function Services() {
    return (
        <div>    
            
            <BackgroundVideo></BackgroundVideo>
            <ServiceDigital></ServiceDigital>
           <ServiceDataFrame></ServiceDataFrame>
           <Footer></Footer>
           
        </div>
    );
}

export default Services;